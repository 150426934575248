import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ExploreMobile from './ExploreMobile'
import Navbar from './Navbar'
import suits from '../suits.json'
import Footer from './Footer'

const Explore = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <>
 <div>
      {isTabletOrMobile ? (<ExploreMobile/>): 
      <>
       <div>
     <Navbar/>
      </div>
    
      <h1 className='exp-title-large'>Koleksioni 2024</h1>
      <div className='suits-group-large'>
    
     {suits.suits.map((suit)=>(
      <div className='card-container-large'>
      <img className='suit-large' src={suit.url} alt="suit" />
      <h2 className='collection'>{suit.name}</h2>
      </div>
     ))}
      </div>
   


      </>
     
     }
    </div>
    <Footer/>
    </>
   
  )
}

export default Explore