import React from 'react'
import "../../src/Explore.css"
import { FaChevronLeft } from "react-icons/fa";
import suits from "../suits.json"



const ExploreMobile = () => {
  return (
    <>
  <div className='header'>
    <div className='btn-group'>
    <a className='back' href="/"> <FaChevronLeft /> kthehu</a>
    <img src="/assets/logo-mobile.png" alt="logo" className='exp-logo' />
    </div>
    
      <h1 className='title'>
       Koleksioni 2024
      </h1>
  </div>
  {suits.suits.map((suit)=> (
         <div className='card'>
          <img className='suit' src={suit.url} alt="" />
          <h4 className='suit-name'>{suit.name}</h4>
          </div>
       ))}
 
    </>
  )
}

export default ExploreMobile