import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./components/Home/Home";
import Explore from "./components/Explore";
import AboutUs from "./components/AboutUs";
import {useMediaQuery} from 'react-responsive';
import AboutMobile from "./components/AboutMobile";
import ContactUs from "./components/ContactUs";
import Products from "./components/Products";
function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div className="App">
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
         </Route>
         <Route path="/explore" element={<Explore />}/>
     {isTabletOrMobile ? ( <Route path="/about" element={<AboutMobile />}/>):(<Route path="/about" element={<AboutUs />}/>)} 
     <Route  path="/contact" element={<ContactUs />} />
     <Route path="/products" element={<Products/>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
