import React from 'react'

const Navbar = () => {
  return (
    <div className='desktop-header'>
    <h1>Absolute Classy</h1>
    <div className='desktop-header-links'>
      <a href="/">Kryefaqja</a>
      <a href="/about">Rreth Nesh</a>
      <a href="/contact">Kontakto</a>
    </div>
  </div>
  )
}

export default Navbar