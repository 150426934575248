import React from 'react'
import { FiInstagram } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { IoChevronForwardCircleOutline } from "react-icons/io5";

import Navbar from '../Navbar';
import suits from '../../suits.json'

import Footer from '../Footer';

const Header = () => {
  return (
    <>
   <Navbar/>
 <div className='hero'>
  <div>
  <h1 className='hero-title'>
    Zbulo kombinimin perfekt për çdo rast.
  </h1>
  <p> Ne besojme se çdo rast kerkon kombinimin dhe stilin e tij te veçantë. Qoftë ai nje event special, takim biznesi, ose thjeshtë nje shetitje, ne kemi zgjedhjen e duhur për të përmbushur nevojat tuaja.</p>
  <a className='explore-btn-desktop' href="/explore">Më Shumë →</a>
  </div>
  <div className='socials-desktop'>
   <a className='instagram' href="https://www.instagram.com/absolute.classy"><FiInstagram /></a>
   <a className='facebook' href="https://www.facebook.com/absolute.classy?mibextid=LQQJ4d"><FaFacebookF /></a>
   <a className='tiktok' href="https://www.tiktok.com/@absolute.classy"><FaTiktok /></a>
   </div>
  </div>
  <h1 className='exp-title-large'>Koleksioni 2024</h1>
      <div className='suits-group-large'>
    
     {suits.suits.slice(3,7).map((suit)=>(
      <div className='card-container-large'>
      <img className='suit-large' src={suit.url} alt="suit" />
      <h2 className='collection'>{suit.name}</h2>
      </div>
     ))}
   
     </div>
     <div className='collection-btn-large'>
     <a className='col-btn-a' href="/explore"><span>Zbulo Koleksionin →</span></a>
      </div>
   <Footer/>
  
   
    </>
 
  )
}

export default Header