import React from 'react'
import NavbarMobile from './NavbarMobile'
import Footer from './Footer'
import 'animate.css';

const AboutMobile = () => {


  return (
<>
<NavbarMobile/>
<div className='mob-abt-page animate__animated animate__zoomIn'>
    <h1 className='about-mobile-title' >Rreth Nesh</h1>
    <div className='abt-cont'>
      <h2>
        Misioni
        </h2>
        <div className='line'></div>
        <p>
        Përkushtimi ynë ndaj shkëlqimit shtrihet përtej veshjeve vetë. 
        ne besojmë në nxitjen e një komuniteti të kavalierëve që e vlerësojnë artin
         dhe traditën e veshjeve të mira për burrat. Përmes burimeve edukative dhe 
         udhëzuesve të stilit, ne i japim fuqi klientëve tanë për të bërë zgjedhje të 
         informuara dhe për të pritur udhëzimet e tyre të stilit personal. Gjithashtu,
        ne aktivisht kërkojmë rregullime dhe marrim pjesë me komunitetin tonë për
         të përmirësuar dhe zhvilluar vazhdimisht ofertat tona.
        </p>
    </div>
   
    <div className='abt-cont'>
      <h2>
        Vizioni
        </h2>
        <div className='line'>
        
        </div>
        <p>
        Në Absolute Classy, vizioni ynë tejkalon modën 'e thjeshtw'.
        Ai përfshin një lëvizje kulturore drejt ridefinimit të maskulinitetit me 
        sofistikim dhe individualitet. Ne vizionojmë një botë ku çdo njeri ndjehet 
        i fuqizuar të shprehë stilin e tij unik me vetëbesim, duke ditur që garderoba
         e tij reflekton identitetin personal dhe respektin për traditën. Përmes
          zgjedhjes sonë të kujdesshme të kostumeve të përpunuara me mjeshtëri, 
          synojmë të frymëzojmë një brez të ri të kavalierëve që e vlerësojnë artin
           dhe mjeshtërinë e veshjeve për burrat.
        </p>
    </div>

    <div className='abt-cont'>
      <h2>
         Qasja
        </h2>
        <div className='line'></div>
        <p>
        Tek baza e qasjes tonë, jemi të përkushtuar jo vetëm për të përmbushur, 
        por për të tejkaluar pritshmëritë e klientëve tanë. Ne besojmë në krijimin 
        e një eksperiencë gjithëpërfshirëse që shkon përtej vetëm transaksionit midis
         nesh, duke kultivuar marrëdhënie të qëndrueshme të ndërtuara mbi besimin dhe
          kënaqësinë. Qëndrimi ynë është i rrënjosur në transparencë dhe autenticitet, 
          duke siguruar që çdo aspekt i biznesit tonë reflekton vlerat tona të 
          integritetit dhe ekselencës. Përmes inovacionit të vazhdueshëm dhe 
          një mendësie progresive, ne përpiqemi të parashikojmë dhe të përmbushim nevojat e klientëve tanë
        </p>
    </div>
   </div>
   <Footer/>
</>
  

  )
}

export default AboutMobile