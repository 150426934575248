import React from 'react'
import { useState } from 'react';
import { FaChevronDown } from "react-icons/fa";


const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
  
const toggleDropdown = () =>{
    setIsOpen(!isOpen)
}

  return (
    <>
    <div className='dropdown'>
    <button className='dropdown-btn ' onClick={toggleDropdown}>Aksesorë <FaChevronDown/></button>
       {isOpen && (
        <>
      
        <a className='dropdown-item' href="/pants">Pantallona</a>
        <a className='dropdown-item' href="/shirts">Këmisha</a>
        <a className='dropdown-item' href="/weistcoat">Jelekë</a>
        <a className='dropdown-item' href="/bowtie">Papjone</a>
        <a className='dropdown-item' href="/suspenders">Tiranda</a>
        <a className='dropdown-item' href="/buttons">Butona</a>
     
     
        </>
       )}
    </div>

    </>
 
  )
}

export default Dropdown