import React from 'react'
import { useMediaQuery } from 'react-responsive'
import NavbarMobile from './NavbarMobile'
import Navbar from './Navbar'


const Products = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <>
    {isTabletOrMobile ? (<NavbarMobile/>) : (<Navbar/> )}
       
    </>
  )
}

export default Products