import React from 'react'
import { useMediaQuery } from 'react-responsive'
import MobileHeader from '../widgets/MobileHeader'
import Header from '../widgets/Header'
import suits from "../../suits.json"
import Footer from '../Footer'
const Home = () => {
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })


  return (
    <>
    <div>
    {isTabletOrMobile ? (<MobileHeader/>) : (<Header/>)}
    </div>
    {isTabletOrMobile ? (
       <div className='arrivals'>
       <h1 className='arrivals-title'>Koleksioni 2024</h1>
       
         {suits.suits.slice(0,3).map((suit)=> (
         <div className='card'>
          <img className='suit' src={suit.url} alt="" />
          <h4 className='suit-name'>{suit.name}</h4>
          </div>
       ))}
       <div className='discover'>
       <a className='discover-btn' href="/explore">Zbulo Koleksionin</a>
       </div>
        <Footer/>
        
       </div>
    
    ) : null}
    

    </>
  )
}

export default Home