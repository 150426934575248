import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { ImInstagram } from "react-icons/im";
import { FaTiktok } from 'react-icons/fa';
import { IoIosMail } from "react-icons/io";
import { FaPhone } from 'react-icons/fa';
const Footer  = () => {
  return (
    <div className='footer-container'>
      <div className='footer-info'>
        <div className='footer-title'>
        <h1>Absolute Classy</h1>
        <img className='footer-logo' src="/assets/logo-mobile.png" alt="footer-logo" />
        </div>
       
        <span>Kostume dhe aksesorë për Meshkuj</span>
        <p>Adresa: Rruga 5 Maji, 15 Tiranë</p>
        <p> <FaPhone style={{margin:'0px 10px'}}/> Tel: +355682707161</p>
        <p> <IoIosMail style={{margin:'0px 10px'}}/> absoluteclassy03@gmail.com</p>
        <p><FaTiktok  style={{margin:'0px 10px'}}/>absolute.classy</p>
        <p><ImInstagram style={{margin:'0px 10px'}}/> absolute.classy</p>
      </div>
   <div className='footer'>
    <h2>Për më shumë informacion na kontaktoni</h2>
    <a className='wts-message' href="https://wa.me/355682707161?text=%C3%A7kemi%2C%20jam%20i%20interesuar%20p%C3%ABr%20nj%C3%AB%20nga%20kostumet%20tuaja"><FaWhatsapp /> <span>via WhatsApp</span> </a>
    <a className='instagram-dm' href="https://www.instagram.com/absolute.classy/?hl=en"><ImInstagram /> <span>via Instagram DM's</span> </a>
    <a className='telephone' href="tel:+355682707161"> <IoIosCall /> via Phone Number</a>
    </div>
    </div>
    
  )
}

export default Footer 