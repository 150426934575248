import React from 'react'
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { HiMenu } from "react-icons/hi";
import Dropdown from '../Dropdown';


const MobileHeader = () => {
const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  return (
    <div className='container-mobile'>
         <div className='header-mobile'>
     <div>
    <button className='menu-btn' onClick={toggleDrawer(true)}><HiMenu/></button> 
    <Drawer open={open} onClose={toggleDrawer(false)}>
      <div className='drawer'>
        <div className='drawer-title-group'>
        <span className='drawer-title'>Absolute</span>
      <span className='drawer-title'>Classy</span>
        </div>
    
      <div className='navbar-links'>
      <a href="/">Kryefaqja</a>
      <a href="/about">Rreth Nesh</a>
      <a href="/contact">Kontakto</a>
      <Dropdown/>
      
      </div>
      </div>
      </Drawer>
  </div>
 <img className='logo' src="/assets/logo-mobile.png" alt="logo" />
</div>
<div className='heading-group'>
  <span>Absolute Classy</span>
   <h1>Zbulo kombinimin perfekt për çdo rast.</h1>
   <a className='heading-btn' href="/explore">Më Shumë... </a>
   </div>
    </div>
 
  )
}

export default MobileHeader