import { useMediaQuery } from 'react-responsive'
import { useForm,} from '@formspree/react';
import React from 'react'
import NavbarMobile from './NavbarMobile'
import Navbar from './Navbar'
import Footer from './Footer'
import { FaWhatsapp } from 'react-icons/fa';
import { ImInstagram } from 'react-icons/im';

const ContactUs = () => {
 
const [state, handleSubmit] = useForm("xqkrngwr");
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

if (state.succeeded) {
  return (
    <>
     {isTabletOrMobile ? (<NavbarMobile/>) : (<Navbar/> )  }  
     
     <div className='submit-page'>
      <img src="/assets/logo-mobile.png" alt="" />
     <h1>Your Form has been successfully submited.</h1>
     <h2>Our Team will review your submission and reach you as soon as possible !</h2> 
     <a href="/"> Go to Homepage <span>→</span></a>
     </div>
    </>
  )
}

  return (
    <>
   {isTabletOrMobile ? (<NavbarMobile/>) : (<Navbar/> )  }  
    <div className='contact-container' >
        <h1>Kontakto</h1>

        <form onSubmit={handleSubmit} method='POST'>
          <label htmlFor="First Name">Emri</label>
          <input type="text" name='First Name' autoComplete='false' required placeholder='First Name' />
          <label htmlFor="Last Name">Mbiemri</label>
          <input type="text" name='Last Name' required placeholder='Last Name'  autoComplete='false'/>
          <label htmlFor="Email">E-Mail</label>
          <input type="email" name='Email' placeholder='E-mail' />
          
          <label htmlFor="message">Mesazhi</label>
          <textarea name="message" id='Message'  rows="10" placeholder='Type your message here'></textarea>
          <button type="submit" disabled={state.submitting} >Dërgo</button>
        </form>
       <div className='reach-us'>
        <h2>
          Ose
        </h2>
        <p>kontaktoni :</p>
        <a className='wts-message' href="https://wa.me/355682707161?text=%C3%A7kemi%2C%20jam%20i%20interesuar%20p%C3%ABr%20nj%C3%AB%20nga%20kostumet%20tuaja"><FaWhatsapp /> <span>via WhatsApp</span> </a>
        <a className='instagram-dm' href="https://www.instagram.com/absolute.classy/?hl=en"><ImInstagram /> <span>via Instagram DM's</span> </a>
       </div>
      <Footer/>
    </div>
    </>
  )
}

export default ContactUs