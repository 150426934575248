import React from 'react'
import Navbar from './Navbar';
import {useMediaQuery} from 'react-responsive';
import Footer from './Footer';

const AboutUs = () => {
const isTabletOrMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (
    <>
    <Navbar/>
     <div>
      {isTabletOrMobile ? null : (
        <div className='accordion'>
            <div>
                <div className='accordion-title'>
                <h1>About Us</h1>
                
                </div>
        
            </div>
            <div className='acc-details'>
               <div className='mission'>
                <div>
                <h2>
                 Misioni
                </h2>
                <p>
                Përkushtimi ynë ndaj elegancës shtrihet përtej veshjeve vetë. 
                ne besojmë në nxitjen e një komuniteti të kavalierëve që e vlerësojnë artin
                dhe traditën e veshjeve të mira për burrat. Përmes burimeve edukative dhe 
                udhëzuesve të stilit, ne i japim fuqi klientëve tanë për të bërë zgjedhje të 
                informuara dhe për të pritur udhëzimet e tyre të stilit personal. Gjithashtu,
                ne aktivisht kërkojmë rregullime dhe marrim pjesë me komunitetin tonë për
                të përmirësuar dhe zhvilluar vazhdimisht ofertat tona.
                </p>
                </div>
               <img src="/assets/desktop-bg.jpg" alt="about-img" />
               </div>
               <div className='vision'>
               <img src="/assets/vision.png" alt="about-img" />
                <div>
                <h2>
                 Vizioni
                </h2>
                <p>
                Në Absolute Classy, vizioni ynë tejkalon modën 'e thjeshtë'.
                Ai përfshin një lëvizje kulturore drejt ridefinimit të maskulinitetit me 
                sofistikim dhe individualitet. Ne vizionojmë një botë ku çdo njeri ndjehet 
                i fuqizuar të shprehë stilin e tij unik me vetëbesim, duke ditur që garderoba
                e tij reflekton identitetin personal dhe respektin për traditën. Përmes
                zgjedhjes sonë të kujdesshme të kostumeve të përpunuara me mjeshtëri, 
                synojmë të frymëzojmë një brez të ri të kavalierëve që e vlerësojnë artin
                dhe mjeshtërinë e veshjeve për burrat.
                </p>
                </div>
                
               </div>
               
               <div className='approach'>
                <div>
                <h2>
                 Qasja
                </h2>
                <p>
                Tek baza e qasjes tonë, jemi të përkushtuar jo vetëm për të përmbushur, 
        por për të tejkaluar pritshmëritë e klientëve tanë. Ne besojmë në krijimin 
        e një eksperiencë gjithëpërfshirëse që shkon përtej vetëm transaksionit midis
         nesh, duke kultivuar marrëdhënie të qëndrueshme të ndërtuara mbi besimin dhe
          kënaqësinë. Qëndrimi ynë është i rrënjosur në transparencë dhe autenticitet, 
          duke siguruar që çdo aspekt i biznesit tonë reflekton vlerat tona të 
          integritetit dhe ekselencës. Përmes inovacionit të vazhdueshëm dhe 
          një mendësie progresive, ne përpiqemi të parashikojmë dhe të përmbushim nevojat e klientëve tanë
                </p>
                </div>
               <img src="/assets/aproach.png" alt="about-img" />
               </div>
            </div>
        </div>
        )}
    </div>
    <Footer/>
    </>
   
  )
}

export default AboutUs